<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import { validationMixin } from "vuelidate";
import { required, helpers, minLength } from 'vuelidate/lib/validators'
import errorHandler from '../../../helpers/errorHandler';
const phoneRegex = helpers.regex('phoneNumberRegex',/^01[0-2,5]{1}[0-9]{8}$/);

/**
 * Starter component
 */
export default {
  page: {
    title: `Assistant Form`,
  },
  data() {
    return {
      title: "Assistant",
      mixins: [validationMixin],
      items: [
        {
          text: "Work center",
          href: "/",
        },
        {
          text: "Assistants",
          href: "/assistants",
        },
        {
          text: "Edits",
          active: true,
        },
      ],
      submit: false,
      backendError: false,
      type: null,
      form:{
        name: "",
        phone_number: "",
        user_name: "",
        password: "",
        id: null,
        role: 2
      },
      passwordType: 'password'
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  validations:{
    form:{
      name: {
        required,
      },
      user_name: {
        required,
        minLength: minLength(8)
      },
      phone_number:{
        required,
        phoneRegex
      },
      password:{
        required,
        minLength: minLength(8)
      }
    }
  },
  methods:{
    handleSubmit() {
      // this.$store.commit("center/CLEAR_ERRORS");
      this.submit = true;
      this.$v.$touch();
      if(!this.$v.$anyError){
        // requests for the create
        this.$store.dispatch("assistant/addAssistant",{
          name: this.form.name,
          user_name: this.form.user_name,
          password: this.form.password,
          phone_number: '2' + this.form.phone_number,
        }).then(
           ()=>{
            this.$router.push('/assistants');
          }
        ).catch((err)=>{
          this.backendError=true;
          errorHandler.methods.error(err);
        });
      }
      
    },
    handleUpdate(){
      // this.$store.commit("accounts/CLEAR_ERRORS");
      this.submit = true;
      this.$v.$touch();
      if(!this.$v.form.phone_number.$error && !this.$v.form.name.$error && !this.$v.form.user_name.$error){
        // requests for the create
        this.$store.dispatch("assistant/editAssistant",{
          name: this.form.name,
          user_name: this.form.user_name,
          phone_number: '2' + this.form.phone_number,
          id: this.form.id
        }).then(
           ()=>{
            this.$router.push('/assistants');
          }
        ).catch((err)=>{
          this.backendError=true;
          errorHandler.methods.error(err);
        });
      }
    },
    togglePassword(){
      if(this.passwordType == 'password'){
        this.passwordType = 'text';
      }else{
        this.passwordType = 'password';
      }
    }
  },
  async created(){
    if(this.$route.params.id){
        this.type = 'edit';
        await this.$store.dispatch('assistant/getAssistantInfo',this.$route.params.id).then( token =>{
            this.form.name = token.name;
            this.form.user_name = token.user_name;
            this.form.phone_number = token.phone_number.substring(1);
            this.form.id = this.$route.params.id;
        }).catch( err =>{
            errorHandler.methods.error(err);
        })
    }else{
        this.type = 'create';
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2 v-if="type == 'create'">Create assistant</h2>
                    <h2 v-if="type == 'edit'">Edit assistant</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Name:"
                        label-for="input-D"
                    >
                        <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.name.$error }" placeholder="Enter assistant name" />
                        <div v-if="submit && $v.form.name.$error" class="invalid-feedback">
                            <span v-if="!$v.form.name.required">This name is required.</span>
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Username:"
                        label-for="input-D"
                    >
                        <input v-model="form.user_name" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.user_name.$error }" placeholder="Enter assistant username" />
                        <div v-if="submit && $v.form.user_name.$error" class="invalid-feedback">
                            <span v-if="!$v.form.user_name.required">This name is required.</span>
                        </div>
                        <div v-if="submit && $v.form.user_name.$error" class="invalid-feedback">
                            <span v-if="!$v.form.user_name.minLength">This name is too short should be 8 characters.</span>
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-phone"
                        label="Phone:"
                        label-for="input-phone"
                    >
                        <div class="row">
                        <div class="col-xl-12">
                            <b-input-group>
                            <input v-if=" type == 'create' " v-model="form.phone_number" type="text" name="phone_number" class="form-control" :class="{ 'is-invalid': submit && $v.form.phone_number.$error }" placeholder="Enter phone number" />
                            <input v-if=" type == 'edit' && form.phone_number" v-model="form.phone_number" type="text" name="phone_number" class="form-control" :class="{ 'is-invalid': submit && $v.form.phone_number.$error }" placeholder="Enter phone number" />
                            <div v-if="submit && $v.form.phone_number.$error" class="invalid-feedback">
                                <span v-if="!$v.form.phone_number.required">This phone is required.</span>
                            </div>
                            <div v-if="submit && $v.form.phone_number.$error" class="invalid-feedback">
                                <span v-if="!$v.form.phone_number.phoneNumberRegex">This value should be phone</span>
                            </div>
                            </b-input-group>
                        </div>
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6" v-if="type == 'create'" >
                    <b-form-group
                        id="input-group-phone"
                        label="Password:"
                        label-for="input-password"
                    >
                        <div class="row">
                        <div class="col-xl-12">
                            <b-input-group class="input-group merge">
                            <input v-if=" type == 'create' " v-model="form.password" :type="passwordType" name="password" class="form-control" :class="{ 'is-invalid': submit && $v.form.password.$error }" placeholder="Enter password" />
                            <div class="input-group-append" data-password="false" @click="togglePassword">
                              <div class="input-group-text">
                                <span class="password-eye"></span>
                              </div>
                            </div>
                            <div v-if="submit && $v.form.password.$error" class="invalid-feedback">
                                <span v-if="!$v.form.password.required">This password is required.</span>
                            </div>
                            <div v-if="submit && $v.form.password.$error" class="invalid-feedback">
                                <span v-if="!$v.form.password.minLength">This value should be more than 8 characters</span>
                            </div>
                            </b-input-group>
                        </div>
                        </div>
                    </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" v-if="type == 'create'">Submit</button>
                <button class="btn btn-outline-success" type="submit" @click="handleUpdate" v-if="type == 'edit'">Update</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>